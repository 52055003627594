var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"row",attrs:{"id":"liveEvent"}},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row align-items-center mb-4"}),_c('div',{staticClass:"table-responsive mb-0"},[_c('b-table',{key:_vm.key,staticClass:"table-bordered table-hover",attrs:{"striped":"","bordered":"","items":_vm.tableData.data,"fields":_vm.fields,"sort-by":_vm.sortBy,"per-page":0,"current-page":_vm.currentPage,"sort-desc":_vm.sortDesc,"filter-included-fields":_vm.filterOn,"responsive":"sm"},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"filtered":_vm.onFiltered},scopedSlots:_vm._u([{key:"cell(edit)",fn:function(row){return [[_c('router-link',{attrs:{"to":{
                              name: 'edit-live-event',
                              params: { id: row.item.content_id },
                           }}},[_c('i',{staticClass:"fa fa-edit edit-i"})])]]}},{key:"cell(members)",fn:function(row){return [[_c('router-link',{attrs:{"to":{
                              name: 'protected-live-event-members',
                              params: { id: row.item.content_id },
                           }}},[_c('i',{staticClass:"fa fa-user"})])]]}}])})],1),(_vm.tableData.total_pages > 1)?[_c('div',{staticClass:"data_paginate"},[_c('div',{staticClass:"dataTables_paginate paging_simple_numbers d-flex justify-content-end"},[_c('ul',{staticClass:"pagination pagination-rounded mb-0"},[_c('b-pagination',{attrs:{"total-rows":_vm.tableData.total,"total-pages":_vm.tableData.total_pages,"per-page":_vm.tableData.per_page,"first-number":"","last-number":""},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)]),_c('div',{staticClass:"form-inline navbar-search"},[_c('span',{staticClass:"mr-2"},[_vm._v("Go to a Particular Paginated Page :")]),_c('div',{staticClass:"input-group"},[_c('b-form-input',{staticClass:"in-width",attrs:{"id":"case","placeholder":"Enter Page No"},model:{value:(_vm.pageNo),callback:function ($$v) {_vm.pageNo=$$v},expression:"pageNo"}}),_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-search",on:{"click":_vm.filterPage}},[_c('i',{staticClass:"fas fa-search fa-sm"})])])],1)])])]:_vm._e()],2)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }