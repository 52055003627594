<template>
   <Layout>
      <PageHeader :title="title" :items="items"></PageHeader>
      <div class="row" id="liveEvent">
         <div class="col-12">
            <div class="card">
               <div class="card-body">
                  <div class="row align-items-center mb-4">
                  </div>
                  <div class="table-responsive mb-0">
                     <b-table striped bordered :items="tableData.data" :fields="fields" :sort-by.sync="sortBy"
                        :per-page="0" :current-page="currentPage" :sort-desc.sync="sortDesc"
                        :filter-included-fields="filterOn" @filtered="onFiltered" responsive="sm"
                        class="table-bordered table-hover" :key="key">
                        <template v-slot:cell(edit)="row">
                           <template v-can="'update-live-event'">
                              <router-link :to="{
                                 name: 'edit-live-event',
                                 params: { id: row.item.content_id },
                              }">
                                 <i class="fa fa-edit edit-i"></i>
                              </router-link>
                           </template>
                        </template>
                        <template v-slot:cell(members)="row">
                           <template v-can="'protected-live-event'">
                              <router-link :to="{
                                 name: 'protected-live-event-members',
                                 params: { id: row.item.content_id },
                              }">
                                 <i class="fa fa-user"></i>
                              </router-link>
                           </template>
                        </template>
                     </b-table>
                  </div>
                  <template v-if="tableData.total_pages > 1">
                     <div class="data_paginate">
                        <div class="dataTables_paginate paging_simple_numbers d-flex justify-content-end">
                           <ul class="pagination pagination-rounded mb-0">
                              <b-pagination v-model="currentPage" :total-rows="tableData.total"
                                 :total-pages="tableData.total_pages" :per-page="tableData.per_page" first-number
                                 last-number></b-pagination>
                           </ul>
                        </div>
                        <div class="form-inline navbar-search">
                           <span class="mr-2">Go to a Particular Paginated Page :</span>
                           <div class="input-group">
                              <b-form-input id="case" v-model="pageNo" placeholder="Enter Page No" class="in-width">
                              </b-form-input>
                              <div class="input-group-append">
                                 <button class="btn btn-search" @click="filterPage">
                                    <i class="fas fa-search fa-sm"> </i>
                                 </button>
                              </div>
                           </div>
                        </div>
                     </div>
                  </template>
               </div>
            </div>
         </div>
      </div>
   </Layout>
</template>

<script>

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import ProtectedLiveEvent from "../../../mixins/ModuleJs/protected-live-event";

export default {
   mixins: [MixinRequest, ProtectedLiveEvent],
   data() {
      return {
         title: "Protected Live Event",
         items: [
            {
               text: "Home",
               href: "/",
            },
            {
               text: "List",
            },
         ],
      }
   },
   components: {
      Layout,
      PageHeader
   }
}

</script>