import { live_event } from "../../js/path";

export default {
  data() {
    return {
      pageNo: null,
      liveEventId: null,
      fields: [
        {
          key: "id",
          label: "ID",
          sortable: true,
        },
        {
          key: "content_id",
          label: "Content ID",
        },
        {
          key: "title.title",
          label: "Title",
        },
        {
          label: "Members",
          key: "members",
        },
        {
          key: "edit",
        },
      ],
      params: "",
      filter: null,
      filterOn: [],
      tableData: [],
      sortBy: "id",
      sortDesc: true,
      key: 0,
      currentPage: 1,
      activeTab: "all",
    };
  },
  methods: {
    filterPage() {
      if (this.pageNo.length > 0) {
        this.params = `&page=${this.pageNo}`;
        this.fetchData(this.activeTab);
        this.currentPage = this.pageNo;
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    //  Get protected live events
    async fetchData(pagination = null) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = live_event.isProtected;
        if (pagination == "search") {
          this.activeTab = pagination;
          url = live_event.isProtected + "?search=" + this.filter;
        } else if (
          pagination == "all" ||
          pagination == "trash" ||
          pagination == "active"
        ) {
          this.activeTab = pagination;
          url = url + "?filter=" + pagination;
        }
        url += this.params;
        const data = await this.getRequest(url);
        if (data.status) {
          this.tableData = data.response;
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (value) {
          this.params = `&page=${value}`;
          this.fetchData(this.activeTab);
          this.pageNo = this.currentPage;
        }
      },
    },
  },
  mounted() {
    this.fetchData();
  },
};
